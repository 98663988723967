<template>
  <div class="page">
    <Login ref="login" :callback="init" />
    <div class="search">
      <van-search
        v-model="text"
        readonly="false"
        @focus="search"
        placeholder="请输入搜索关键词"
      ></van-search>
    </div>
    <div class="classify">
      <van-grid>
        <van-grid-item
          :text="classify.name"
          v-for="classify in classifys"
          :key="classify.code"
          @click="classifySearch(classify.code)"
        >
          <template #icon>
            <svg class="icon" aria-hidden="true">
              <use :xlink:href="classify.icon"></use>
            </svg>
          </template>
        </van-grid-item>
      </van-grid>
    </div>
    <div class="title"><van-icon name="notes-o" /> 热门名校</div>
    <van-list
      :finished="finished"
      finished-text="没有更多了"
      offset="0"
      class="task-list"
      :immediate-check="false"
      @load="retrieveTaskHotList"
    >
      <template #default>
        <van-row v-for="task in taskList" :key="task.code">
          <van-col @click="detail(task.code)" span="24">
            <van-row class="task">
              <van-col span="8">
                <div class="image">
                  <van-image radius="3" :src="task.imagePath"></van-image></div
              ></van-col>
              <van-col span="16">
                <van-row>
                  <van-col span="24" class="name">{{ task.name }}</van-col>
                </van-row>
                <van-row>
                  <van-col span="12" class="count color-s1"
                    >数量：{{ task.count }}人</van-col
                  >
                  <van-col span="12" class="classify"
                    >类型：<van-tag
                      :color="COLOR_M"
                      v-if="task.schoolType === 'GOV'"
                      >公办</van-tag
                    ><van-tag :color="COLOR_S1" v-if="task.schoolType === 'CLV'"
                      >民办</van-tag
                    ><van-tag :color="COLOR_S2" v-if="task.schoolType === 'ITN'"
                      >国际</van-tag
                    ></van-col
                  >
                </van-row>
                <van-row>
                  <van-col span="24" class="time"
                    >时间：{{ task.startTime }} 到 {{ task.endTime }}</van-col
                  >
                </van-row>
              </van-col>
            </van-row>
          </van-col>
        </van-row>
        <van-row v-if="taskList.length <= 0">
          <van-col span="24" class="no-record">
            <van-image
              width="103"
              height="103"
              :src="require('../../../../assets/images/home/no-record.png')"
            ></van-image>
          </van-col>
        </van-row>
      </template>
    </van-list>
    <van-overlay :show="wechatShow">
      <div class="wechat">
        <div>
          <img :src="STATIC_URL + '/' + store.linkWechat" class="img" />
        </div>
        <div class="text">长按二维码添加微信</div>
        <div class="button">
          <van-button
            type="primary"
            size="mini"
            block
            @click="wechatShow = false"
            >关闭</van-button
          >
        </div>
      </div>
    </van-overlay>
    <van-overlay :show="guideShow">
      <div class="wrapper" @click="guideShow = false">
        <div class="guide">
          <van-image
            class="img"
            :src="require('../../../../assets/images/guide.jpg')"
          ></van-image>
          <van-button
            plain
            :color="COLOR_M"
            size="mini"
            class="btn"
            icon="info-o"
            @click="guideShow = false"
            >我了解了</van-button
          >
        </div>
      </div>
    </van-overlay>
    <Loading :show="loadingShow" />
    <Share ref="share" />
    <LogVisit ref="logVisit" module-type="ERO" module-label="ERO_HOME_INDEX" />
    <Tabbar mode="enroll" active="home" v-if="platform === 'WPP'" />
  </div>
</template>
<script>
import { Search, Grid, GridItem, Icon, Cell, NoticeBar, Tag, Overlay, Image, List } from 'vant'
import Tabbar from '../common/Tabbar.vue'
import Login from '../../intercept/Login.vue'
import Share from '../../common/Share.vue'
import LogVisit from '../../common/LogVisit.vue'
import Loading from '../../common/Loading.vue'
import '../../../../assets/icon/font-icon.js'
export default {
  components: {
    Tabbar: Tabbar,
    Login: Login,
    Share: Share,
    LogVisit: LogVisit,
    Loading: Loading,
    [Search.name]: Search,
    [Grid.name]: Grid,
    [GridItem.name]: GridItem,
    [Icon.name]: Icon,
    [Cell.name]: Cell,
    [NoticeBar.name]: NoticeBar,
    [Tag.name]: Tag,
    [Overlay.name]: Overlay,
    [Image.name]: Image,
    [List.name]: List
  },
  data () {
    return {
      platform: '',
      loadingShow: false,
      wechatShow: false,
      guideShow: false,
      finished: false,
      store: { name: '', address: '', linkPerson: '', linkPhone: '', linkWechat: '', type: '' },
      code: '',
      taskList: [],
      classifys: [],
      student: {},
      page: { current: 0, size: 10, last: 0 },
      loginState: 'N',
      studentState: 'N'
    }
  },
  mounted () {
    document.title = '上学咨询'
    this.$refs.login.validate()
  },
  methods: {
    init () {
      this.platform = window.sessionStorage.getItem(this.SESSION_PLATFORM)
      var token = window.sessionStorage.getItem(this.SESSION_TOKEN)
      if (token !== undefined && token !== '' && token !== null) {
        var guide = window.sessionStorage.getItem('guide_show')
        if (guide !== 'N') {
          // this.guideShow = true
          // window.sessionStorage.setItem('guide_show', 'N')
        }
        this.retrieveClassifys()
        this.retrieveTaskHotList()
        this.initShare()
        this.$refs.logVisit.createLog()
      }
    },
    async retrieveClassifys () {
      var pd = { upperCode: '0' }
      const { data: res } = await this.$http.post(this.BMS_URL + '/enroll/classify/retrieveClassifyList', this.$qs.stringify(pd))
      if (res.status === '200') {
        this.classifys = res.data
      }
    },
    initShare () {
      var title = '重点名校入学咨询'
      var desc = '小学、初中、高中、大学、国内国际硕士博士入学咨询，让您的孩子赢在起跑线'
      var link = 'https://moc.utopanet.com/enroll/home?1=1'
      var logo = 'https://moc.utopanet.com/static/logo.png'
      this.$refs.share.initParam(title, desc, link, logo)
    },
    async retrieveTaskHotList () {
      if (!this.finished) {
        this.loadingShow = true
        this.page.current = this.page.current + 1
        var operator = window.sessionStorage.getItem(this.SESSION_OPERATOR)
        var pd = { operatorCode: operator, current: this.page.current, size: this.page.size }
        var { data: res } = await this.$http.post(this.BMS_URL + '/enroll/taskAPC/retrieveTaskHotList', this.$qs.stringify(pd))
        if (res.status === '200') {
          this.taskList = this.taskList.concat(res.data)
          this.page.last = res.page.last
          if (res.data.length <= 0) {
            this.finished = true
          }
        }
        this.loadingShow = false
      }
    },
    async retrieveOperator () {
      var operator = window.sessionStorage.getItem(this.SESSION_OPERATOR)
      var pd = { code: operator }
      const { data: res } = await this.$http.post(this.UMS_URL + '/operate/operator/retrieveOperator', this.$qs.stringify(pd))
      if (res.status === '200') {
        this.store = res.data
      }
    },
    classifySearch (code) {
      this.$router.push({ path: '/enroll/search', query: { classifyCode: code } })
    },
    detail (code) {
      this.$router.push({ path: '/enroll/detail', query: { taskCode: code } })
    },
    search () {
      this.$router.push({ path: '/search/home', query: { type: 'ERO' } })
    }
  }
}
</script>
<style lang="less" scoped>
.page {
  background: #fff;
  ::v-deep .search {
    .van-search {
      background-color: transparent;
    }
  }
}
.adv {
  padding: 5px 0px;
}
.title {
  height: 25px;
  line-height: 25px;
  text-align: left;
  font-size: 14px;
  font-weight: 800;
  border-bottom: 1px solid #eee;
  padding: 5px 10px;
}
.classify {
  margin-top: 1px;
  .icon {
    width: 30px;
    height: 30px;
  }
}
.task-list {
  padding-bottom: 60px;
  width: 100%;
  .task {
    text-align: left;
    margin: 5px;
    padding-bottom: 5px;
    border-bottom: 1px solid #eee;
    .name {
      padding-left: 10px;
      font-weight: 600;
      height: 25px;
      line-height: 25px;
      text-align: left;
      font-size: 14px;
      overflow: hidden;
      white-space: nowrap;
      text-overflow: ellipsis;
      -webkit-box-orient: vertical;
      -webkit-line-clamp: 1;
    }
    .count {
      padding-left: 10px;
      font-size: 12px;
    }
    .classify {
      text-align: center;
      height: 22px;
      line-height: 22px;
      font-size: 12px;
    }
    .time {
      padding-left: 10px;
      font-size: 12px;
      height: 22px;
      line-height: 22px;
      color: #8a8a8a;
      text-align: left;
    }
    ::v-deep .van-tag {
      font-size: 10px;
    }
  }
}
.wrapper {
  display: flex;
  flex-flow: column;
  align-items: center;
  justify-content: center;
  height: 100%;
  .guide {
    background-color: #fff;
    width: 80%;
    padding-bottom: 30px;
    .img {
      width: 100%;
    }
    .btn {
      width: 60%;
    }
  }
}
</style>
